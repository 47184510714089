<template>
  <div>
    <div>
      <div class="w-full" v-if="(user.role == 'admin')">
        <textarea id="big-input" v-model="admin_notes" class="w-full h-48 p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm" placeholder="Anteckningar för admin" :disabled="isDisabled"></textarea>
      </div>
      <div class="w-full pt-2 pb-4 items-center" v-if="(user.role == 'admin')">
        <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click="toggleDisabled">
          {{ isDisabled ? 'Lås upp anteckningar' : 'Lås anteckningar' }}
          <BaseIcon :icon="isDisabled ? 'lock' : 'lock-open'" class="ml-1"/>
        </button>
        <button class="btn-green-outline mx-2 mt-3 sm:mt-0" v-if="(!this.isDisabled)" @click.prevent="saveAdminNotes()">
          Spara
          <BaseIcon icon="save" class="ml-1" />
        </button>
      </div>
      <div class="flex">
        <div class="w-full bg-action-bar px-2 pt-0 pb-3 sm:pt-2 sm:pb-2 clearfix">
          <div class="float-left flx flex-wrap w-1/5 pt-1">
            <input v-model="searchQuery" type="text" :placeholder="$t('operations_page.unit_search')" class="w-full border border-gray-300 rounded py-1 mt-3 text-sm sm:mt-0 pl-3"/>
          </div>
          <div class="float-right flex flex-wrap pt-1">
            <button class="btn-secondary-outline mx-2 mt-3 sm:mt-0" @click.prevent="expandUnits()" v-if="filteredUnits && filteredUnits.length > 0">
              <span v-if="!all_expanded">
                {{ $t("operations_page.expand") }}
                <BaseIcon icon="plus-circle" class="ml-1" />
              </span>
              <span v-else>
                {{ $t("operations_page.minimize") }}
                <BaseIcon icon="minus-circle" class="ml-1" />
              </span>
            </button>
            <router-link class="btn-secondary-outline mx-2 mt-3 sm:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('trip_report') > -1)) && filteredUnits && filteredUnits.length > 0" :to="'/trip-report/customer/' + customer_id">
              {{ $t("operations_page.trip_report") }}
              <BaseIcon icon="truck" class="ml-1" />
            </router-link>
            <router-link class="btn-secondary-outline mx-2 mt-3 sm:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('securtrack') > -1)) && filteredUnits && filteredUnits.length > 0" :to="'/gps-kund/' + customer_id">
              {{ $t("operations_page.securTrack") }}
              <BaseIcon icon="map-marker-alt" class="ml-1" />
            </router-link>
            <router-link class="btn-secondary-outline mx-2 mt-3 sm:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('securtool') > -1)) && filteredUnits && filteredUnits.length > 0" :to="'/ronderingsbevakning/customer/' + customer_id">
              {{ $t("operations_page.securTool") }}
              <BaseIcon icon="history" class="ml-1" />
            </router-link>
            <router-link class="btn-secondary-outline mx-2 mt-3 sm:mt-0" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('test_reminder') > -1)) && filteredUnits && filteredUnits.length > 0" :to="'/alarm-reminder/customer/' + customer_id">
              {{ $t("operations_page.test_alarm") }}
              <BaseIcon icon="bell" class="ml-1" />
            </router-link>
            <button class="btn-red-outline mx-2 mt-3 sm:mt-0" v-if="(user.role == 'admin' || user.role == 'seller') && filteredUnits && filteredUnits.length > 0" @click.prevent="deleteUnitsPrompt()">
              {{ $t("operations_page.radera") }}
              <BaseIcon icon="trash" class="ml-1" />
            </button>
            <router-link v-if="user.role == 'superuser' || user.role == 'user' || user.role == 'admin' || user.role == 'seller' || user.role == 'reseller'" class="btn-green-outline mx-2 mt-3 sm:mt-0" :to="'/admin/skapa-verksamhet/' + customer_id">
              {{ $t("operations_page.new_business") }}
              <BaseIcon icon="plus" class="ml-1" />
            </router-link>
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("operations_page.export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="filteredUnits && filteredUnits.length > 0" class="unit-wrapper">
        <div class="grid bg-primary-50 rounded mt-5 py-5 px-4" v-for="(unit, index) in filteredUnits" :key="index">
          <div
            class="clearfix pb-2"
            v-bind:class="{ 'cursor-pointer border-b border-secondary-500': unit.alarms.length > 0 }"
            @click.prevent="
              unit.isAlarmVisible = !unit.isAlarmVisible;
              checkExpandUnit();
            "
          >
            <div class="float-left unit-desc">
              <span class="checkbox-wrapper mr-4">
                <input type="checkbox" class="export-checkbox" :value="unit._id" v-model="exportUnits" v-on:click.stop="" />
              </span>
              <span v-on:click.stop="$router.push('/larm/' + type + '/' + customer_id + '/' + unit.unit_id + '/' + id)" class="cursor-pointer">
                <span class="font-serif text-sm font-semibold mr-1 hover:text-primary-500 transition duration-200 underline" v-bind:class="{ active: unit.isAlarmVisible }">{{ unit.name }}</span>
                <span class="text-xs text-gray-400 hover:text-gray-500 transition duration-200">- {{ unit.company_number }}</span>
                <span v-if="/^admin|^seller/.test(user.role)" class="text-xxs text-gray-500 ml-8">{{ getSellerName(unit.seller_id) }}</span>
              </span>
            </div>
            <div v-if="unit.alarms.length > 0" class="float-right">
              <BaseIcon v-if="!unit.isAlarmVisible" class="dropdown-icon text-primary-500" icon="plus-circle" />
              <BaseIcon v-if="unit.isAlarmVisible" class="dropdown-icon text-primary-500" icon="minus-circle" />
            </div>
          </div>
          <div class="alarm-item mt-3 border-b border-secondary-400 pt-1 pb-3" v-show="unit.isAlarmVisible" v-for="(alarm, c) in unit.alarms" :key="c">
            <router-link tag="span" class="alarm-name text-sm font-semibold cursor-pointer hover:text-primary-500 transition duration-200" :to="'/larm/' + type + '/' + customer_id + '/' + unit.unit_id + '/' + id + '/' + alarm._id">
              <span v-if="!/(SRT334)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(AJAX)/.test(alarm.type) && checkAlarmGPS(alarm)" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: getAlarmGPSTime(alarm.imei_number) > 1000 * 60 * 60 }">⬤</span>
              <span v-if="/(SRT334)|(TWIG Beacon)|(Anchor Beacon)|(AJAX)/.test(alarm.type)">
                <span v-if="alarm.last_rftag_activity" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: getTagLastSeenDays(alarm) > 56, orange: getTagLastSeenDays(alarm) > 28 && getTagLastSeenDays(alarm) <= 56 }">⬤</span>
                <span v-else class="last-seen grey mr-2 text-sm">⬤</span>
              </span>
              <span v-if="/(4G Box)/.test(alarm.type)">
                <span v-if="alarm.last_rftag_activity" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: getTagLastSeenDays(alarm) > 7, orange: getTagLastSeenDays(alarm) > 2 && getTagLastSeenDays(alarm) <= 7 }">⬤</span>
                <span v-else class="last-seen grey mr-2 text-sm">⬤</span>
              </span>
              <span>{{ alarm.unit_name }}</span>
              <span class="text-xs text-gray-400 hover:text-gray-500 transition duration-200 ml-3 font-light">- {{ alarm.phone }}</span>
            </router-link>
            <span class="float-right" v-if="alarm.type != 'SRT334' && checkAlarmGPS(alarm)">
              <router-link class="btn-green" :to="'/gps-unit/' + type + '/' + unit.unit_id + '/' + id + '/' + alarm._id">
                {{ $t("operations_page.track") }}
                <BaseIcon icon="map-marker-alt" class="ml-2" />
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <div class="py-8 text-center border-b" v-if="filteredUnits && filteredUnits.length == 0">
        {{ $t("operations_page.no_business_found") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return this.$t("page_titles.departments");
  },
  data() {
    return {
      type: this.$route.params.type,
      customer_id: this.$route.params.customer_id,
      id: this.$route.params.id,
      customer_info: null,
      customer_units: null,
      units: null,
      reseller: null,
      all_expanded: false,
      exportUnits: [],
      gpsAlarms: [],
      isDisabled: true,
      admin_notes: "", 
      searchQuery: "",
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    filteredUnits() {
      if (!this.searchQuery) {
        return this.customer_units;
      }
      return this.customer_units.filter((unit) =>
        unit.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },

  methods: {
    getCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/${this.customer_id}/units/alarms/`)
        .then((response) => {
          this.customer_info = response.data.customer;
          this.gpsAlarms = response.data.gps_alarms;
          this.admin_notes = response.data.customer.admin_notes

          this.setPageTitle(`${this.customer_info.name} - ${this.$t("top_titles.departments")}`, "units");

          this.customer_units = [];
          // export
          this.exportUnits = [];

          response.data.units.forEach((el) => {
            el.isAlarmVisible = false;

            let productsSplit = _.partition(el.alarms, function(o) {
              return /(^SRT334$)|(^TWIG Beacon$)|(^4G Box$)|(Anchor Beacon)|(AJAX)/.test(o.type);
            });
            let alarms = [...productsSplit[1], ...productsSplit[0]];
            el.alarms = alarms;

            this.customer_units.push(el);
            // export
            this.exportUnits = [...this.exportUnits, el._id];
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getResellerUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.id}/customer/${this.customer_id}/units`)
        .then((response) => {
          this.customer_info = response.data.customer;
          this.reseller = response.data.reseller;
          this.gpsAlarms = response.data.gps_alarms;

          this.setPageTitle(`${this.customer_info.name} - ${this.$t("top_titles.departments")}`, "units");

          this.customer_units = [];
          // export
          this.exportUnits = [];

          response.data.units.forEach((el) => {
            el.isAlarmVisible = false;

            let productsSplit = _.partition(el.alarms, function(o) {
              return /(^SRT334$)|(^TWIG Beacon$)|(Anchor Beacon)|(AJAX)/.test(o.type);
            });
            let alarms = [...productsSplit[1], ...productsSplit[0]];
            el.alarms = alarms;

            this.customer_units.push(el);
            // export
            this.exportUnits = [...this.exportUnits, el._id];
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnitName(unit_id) {
      // todo: replace unit_id with mongo _id
      let u = _.find(this.customer_units, { unit_id: unit_id });
      if (u) return u.name;
      else return "";
    },

    deleteUnitsPrompt() {
      if (this.exportUnits.length > 0) {
        this.$modal.show("dialog", {
          title: `<span class="text-red-500">Radera ${this.exportUnits.length} verksamhet?</span>`,
          buttons: [
            {
              title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">Avbryt</div>',
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
            {
              title: '<div class="bg-red-500 text-white text-sm font-sans py-2">Radera</div>',
              handler: () => {
                this.deleteSelectedUnits();
              },
            },
          ],
        });
      }
    },

    async deleteSelectedUnits() {
      try {
        let data = {
          unitIDs: this.exportUnits,
        };

        // eslint-disable-next-line
        let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/units/delete`, data);

        this.$modal.hide("dialog");
        this.handleSuccess("Verksamhet har tagits bort");
        this.getCustomer();
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    exportXLS() {
      let exportData = [];
      this.exportUnits.forEach((_id) => {
        let unit = _.find(this.customer_units, { _id: _id });
        exportData = [...exportData, ...unit.alarms];
      });

      let export_columns = [];

      exportData.forEach((alarm) => {
        export_columns.push({
          [this.$t("department_export.department")]: this.getUnitName(alarm.parent_id),
          [this.$t("department_export.device_type")]: alarm.type,
          [this.$t("department_export.device_name")]: alarm.unit_name,
          [this.$t("department_export.phone")]: alarm.phone,
          [this.$t("department_export.serial_number")]: alarm.modelnumber,
        });
      });

      export_columns = _.sortBy(export_columns, ["Verksamhet", "Enhetsnamn"]);

      this.excelExport(export_columns, "larms", "larm_rapport");
    },

    expandUnits() {
      this.all_expanded = !this.all_expanded;
      this.filteredUnits.forEach((unit) => {
        if (this.all_expanded) unit.isAlarmVisible = true;
        else unit.isAlarmVisible = false;
      });
    },

    checkExpandUnit() {
      let all_expanded = false;
      this.filteredUnits.forEach((unit) => {
        if (unit.isAlarmVisible && unit.alarms.length > 0) all_expanded = true;
      });

      if (all_expanded) this.all_expanded = true;
      else this.all_expanded = false;
    },

    checkAlarmGPS(alarm) {
      if (alarm.gps_active) {
        let a = _.find(this.gpsAlarms, { imei_number: alarm.imei_number });
        if (a) return true;
        else return false;
      } else return false;
    },

    getAlarmGPSTime(imei) {
      let a = _.find(this.gpsAlarms, { imei_number: imei });
      if (a) {
        return this.moment().diff(this.moment(a.updatedAt));
      }
    },

    getTagLastSeenDays(tag) {
      let tagLastSeen = this.moment().diff(this.moment(tag.last_rftag_activity), "days");
      return tagLastSeen;
    },

    checkGPSLogAddon() {
      if (this.$store.state.addons.findIndex((e) => e == "rounding_surveillance") >= 0) return true;
      else if (this.user.role == "admin") return true;
      else return false;
    },

    getSellerName(id) {
      let s = _.find(this.sellers, { _id: id });
      if (s) return s.name;
      else return "";
    },
    toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
    async saveAdminNotes() {
      let data = {
          admin_notes: this.admin_notes
      };
      if (this.customer_id && data) {
        let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/customer/admin-notes/${this.customer_id}`, data);
        if (!response) {
          this.handleError(response.message);
        } else {
          this.toggleDisabled()
        }
      } else return
    }
  },

  created() {
    if (this.type == "customer" && (this.user.role == "superuser" || this.user.role == "user" || this.user.role == "admin" || this.user.role == "seller")) this.getCustomer();
    else if (this.type == "reseller" && (this.user.role == "reseller" || this.user.role == "admin")) this.getResellerUnits();
    else this.customer_units = [];

    if (this.user.role == "admin") this.getSellers();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.departments");
      this.setPageTitle(`${this.customer_info.name} - ${this.$t("top_titles.departments")}`, "units");
    },
  },
};
</script>
